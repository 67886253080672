// KOPFUNDSTEINE SKIN <- BLUMFELD SKIN <- JIRAFA SKIN
@import 'fonts';

$msdockville-font-family: 'Prophet-Medium', $font-family-sans-serif;
$spektrum-font-family: 'PPMonumentExtended', $font-family-sans-serif;
$vogelball-font-family: 'Ambiant-Book', $font-family-sans-serif;
$habitat-font-family: 'RauschenB-Bold', $font-family-sans-serif;
$msartville-font-family: 'Prophet-Medium', $font-family-sans-serif;

#root {
  padding-top: 0;
}

.btn:not(.btn-link),
.loading-spinner-content {
  text-transform: uppercase;
}

#header .navbar {
  box-shadow: $box-shadow-xl;
  z-index: $zindex-sticky + 2;
}
#header .navbar .navbar-brand,
.navbar .navbar-brand {
  img {
    height: $calculated-navbar-height-sm;
    max-height: $calculated-navbar-height-sm;

    @include media-breakpoint-up(md) {
      height: $calculated-navbar-height;
      max-height: $calculated-navbar-height;
    }

    &:hover,
    &:focus {
      filter: opacity(0.66);
    }
  }
}
.navbar .navbar-brand {
  padding-left: $navbar-nav-link-padding-x;
}
#header .navbar .navbar-brand {
  padding-left: 0;
}

.cover-photo {
  // height: 50vh;
  width: 100%;
  // object-fit: cover;
}

.ticket-detail .product-header > .container {
  width: 100% !important;
  max-width: none !important;
  padding: 0 !important;
  margin: 0;
}

.msdockville {
  &,
  .product-content {
    background: $white;
  }

  .navbar .navbar-brand {
    img {
      height: 2rem;
      max-height: 2rem;

      @include media-breakpoint-up(md) {
        height: 2.5rem;
        max-height: 2.5rem;
      }
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6,
  .btn:not(.btn-link),
  .title,
  legend {
    font-family: $msdockville-font-family;
  }

  .ticket-detail {
    .card.article-title {
      color: $white;
      background: $msdockville-primary;
      .super-title {
        text-transform: uppercase;
      }
    }
  }

  .btn-primary {
    @include button-variant($background: $msdockville-secondary, $border: $msdockville-secondary);
    color: $black;
  }

  //FILTER BUTTONS OVER TICKET LISTING

  .listing-filter-buttons-container {
    h5 {
      text-align: center;
      margin-top: 1.5rem;
    }
  }
  .listing-buttons-container {
    .btn {
      border-radius: 0;
      font-size: $h4-font-size;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover,
      &:active,
      &:focus {
        background-color: $msdockville-secondary !important;
        color: $black !important;
      }
    }
  }
}

.msdockville--haspa {
  &,
  .product-content {
    background: $white;
  }

  .navbar .navbar-brand {
    img {
      height: 2rem;
      max-height: 2rem;

      @include media-breakpoint-up(md) {
        height: 3rem;
        max-height: 3rem;
      }
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6,
  .btn:not(.btn-link),
  .title,
  legend {
    font-family: $msdockville-font-family;
  }

  .ticket-detail {
    .card.article-title {
      color: $white;
      background: #de002e;
      .super-title {
        text-transform: uppercase;
      }
    }
  }

  .nigiri-cta {
    display: flex;
    flex-direction: column;
  }

  .nigiri-cta .former-price {
    text-decoration: line-through;
    color: $msdockville--haspa-primary;
    margin-top: 0.3rem;
  }

  .btn-primary {
    @include button-variant($background: $msdockville--haspa-primary, $border: $msdockville--haspa-primary);
    color: $white;
  }
}

.spektrum {
  &,
  .product-content {
    background: $white;
  }

  .navbar .navbar-brand {
    img {
      height: 2rem;
      max-height: 2rem;

      @include media-breakpoint-up(md) {
        height: 3rem;
        max-height: 3rem;
      }
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6,
  .btn:not(.btn-link),
  .title,
  legend {
    font-family: $spektrum-font-family;
  }

  .ticket-detail {
    .card.article-title {
      background: $spektrum-secondary;
      color: $black;
    }
  }
}

.vogelball {
  &,
  .product-content {
    background: $white;
  }

  .navbar .navbar-brand {
    img {
      height: 2rem;
      max-height: 2rem;
      max-width: 6rem;

      @include media-breakpoint-up(md) {
        height: 2rem;
        max-height: 3rem;
        max-width: 9rem;
      }
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6,
  .btn:not(.btn-link),
  .title,
  legend {
    font-family: $vogelball-font-family;
    font-size: 14pt;
  }

  .ticket-detail {
    .card.article-title {
      background: $vogelball-primary;
      color: $white;
    }
    .text-muted {
      color: darken($white, 10%);
    }
  }

  .btn-primary {
    @include button-variant($background: $vogelball-primary, $border: $vogelball-primary);
    color: $white;
  }

  .maki-2020 .overlay {
    background: rgba(0, 0, 0, 0.25) !important;
  }
}

.habitat {
  &,
  .product-content {
    background: $white;
  }

  .navbar .navbar-brand {
    img {
      height: 2rem;
      max-height: 2rem;

      @include media-breakpoint-up(md) {
        height: 3rem;
        max-height: 3rem;
      }
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6,
  .btn:not(.btn-link),
  .title,
  legend {
    font-family: $habitat-font-family;
  }

  .ticket-detail {
    .card.article-title {
      background: $habitat-secondary;
      color: $white;
    }
    .text-muted {
      color: darken($white, 10%);
    }
  }

  .btn-primary {
    @include button-variant($background: #e98b89, $border: #e98b89);
    color: $white;
  }
}

.msartville {
  &,
  .product-content {
    background: $white;
  }

  .navbar .navbar-brand {
    img {
      height: 2rem;
      max-height: 2rem;

      @include media-breakpoint-up(md) {
        height: 3rem;
        max-height: 3rem;
      }
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6,
  .btn:not(.btn-link),
  .title,
  legend {
    font-family: $msartville-font-family;
  }

  .ticket-detail {
    .card.article-title {
      color: $white;
      background: $msartville-primary;
    }
  }

  .btn-primary {
    @include button-variant($background: #f5824c, $border: #f5824c);
    color: $white;
  }
}

.bg-msdockville {
  background: $msdockville-primary;
}

.bg-msdockville--haspa {
  background: $msdockville--haspa-primary;
}

.bg-spektrum {
  background: $spektrum-primary;
}
.bg-vogelball {
  background: $vogelball-primary;
}
.bg-habitat {
  background: $habitat-primary;
}
.bg-msartville {
  background: $msartville-primary;
}

.navbar > .hamburger {
  margin-left: -$navbar-padding-x;
}

.navbar .secondary-menu,
.navbar .main-menu {
  display: flex;
  align-items: center;
}

.navbar-toggler {
  border: none;
  transition: transform 0.4s;
  padding: 0;
  outline: 0 !important;

  .navbar-toggler-icon {
    width: 2em;
    height: 2em;
  }
}

// .navbar .nav-item.last {
//   .nav-link {
//     @include media-breakpoint-down(sm) {
//       padding-right: 0px;
//     }
//   }
// }

.offcanvas-collapse {
  top: $calculated-navbar-height-sm + 2rem;
  @include media-breakpoint-up(md) {
    top: $calculated-navbar-height + 2rem;
  }
  //background-color: $gray-900;
  //box-shadow: inset 0 20px 15px -20px rgba(0,0,0,.15);
  .navbar-nav .nav-item {
    margin-bottom: 0.5rem;
    background-color: rgba($color: #2b2b2b, $alpha: 0.9) !important;
    a {
      color: #fff;
    }
    a.active,
    a:hover {
      color: $gray-500;
      font-style: italic;
    }
    // font-family: $font-family-heading;
    font-size: $h5-font-size;
    text-transform: uppercase;
    box-shadow: -20px 0px 20px 0px rgba(0, 0, 0, 0.5);
  }
}

.offcanvas-collapse {
  //box-shadow: 15px 10px 70px -35px rgba(0,0,0,0.75);
  box-shadow: none;
  background-color: transparent;
}
.offcanvas-collapse-left {
  bottom: auto;
  overflow: visible;
}

.validation-alert {
  @extend .text-danger;

  .validation-alert--message {
    @extend .font-weight-bold;
  }
}

// OC CART

.offcanvas-collapse-right {
  background-color: rgba($color: #fff, $alpha: 0.9) !important;
  box-shadow: -15px 10px 70px -35px rgba(0, 0, 0, 0.75);
  h5 {
    font-size: $h5-font-size;
    text-transform: uppercase;
    color: $gray-900 !important;
  }
}

// MAKI

.maki {
  .card-title {
    //    text-align: center;
    font-size: 1.5rem;
    .title {
      font-size: 1.5rem;
    }
    .sub-title {
      font-size: smaller;
    }
    .price {
      font-size: 1rem;
    }
  }
  .maki-img {
    transition: transform 0.3s, box-shadow 0.3s;
    transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  }
  .maki-img:hover,
  .maki-img:active,
  .maki-img:focus {
    transform: scale(1.01);
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  }
}

.maki-2020 {
  .maki-img {
    margin-bottom: 90px;
  }
  .overlay {
    background: hsla(0, 0, 0, 25%);
    backdrop-filter: blur(15px) saturate(120%);
  }
  .card-title {
    font-size: 1.9em;
  }
  .card-body {
    background: hsla(0, 0, 100%, 50%);
    .start_on {
      display: none;
    }
  }
  &:hover,
  &:active,
  &:focus {
    .overlay {
      background: hsla(0, 0, 0, 100%);
      backdrop-filter: blur(15px) saturate(120px);
      opacity: initial;
    }
  }
}

// NIGIRI

.product-listing-page {
  .msdockville .nigiri.card {
    border: 0.1rem solid rgba($msdockville-primary, 0.5);
    font-family: $msdockville-font-family;
  }
  .msdockville--haspa .nigiri.card {
    border: 0.1rem solid rgba($msdockville--haspa-primary, 0.5);
    font-family: $msdockville-font-family;
  }
  .spektrum .nigiri.card {
    border: 0.1rem solid rgba($spektrum-primary, 0.5);
    font-family: $spektrum-font-family;
  }
  .habitat .nigiri.card {
    border: 0.1rem solid rgba($habitat-primary, 0.5);
    font-family: $habitat-font-family;
  }
  .vogelball .nigiri.card {
    border: 0.1rem solid rgba($vogelball-primary, 0.5);
    font-family: $vogelball-font-family;
  }
  .msartville .nigiri.card {
    border: 0.1rem solid rgba($msartville-primary, 0.5);
    font-family: $msartville-font-family;
  }

  .nigiri.card {
    background-color: transparent;
    border-radius: 0;
    //border-bottom: 1px solid rgba($white, 0.25);

    .nigiri-content {
      padding: 1.25rem;
      margin-top: 0.2rem;
      color: $black;

      @include media-breakpoint-up(md) {
        grid-template-columns: 20ch 2fr auto;
        align-items: center;
      }
      @include media-breakpoint-down(md) {
        display: table;
      }
    }
    .nigiri-date {
      font-size: $h3-font-size;
      font-family: $headings-font-family;
      color: $primary;
      font-weight: 900;
      color: $secondary;
      align-items: center !important;
      @include media-breakpoint-down(md) {
        font-size: $h5-font-size;
      }
    }
    .nigiri-body {
      font-size: $h2-font-size;
      border-left: 0;
      text-transform: uppercase;
      font-weight: bold;
      padding-left: 2.5rem;
      padding-right: 1rem;
      @include media-breakpoint-down(md) {
        padding-left: 0;
        padding-right: 0;
        line-height: 2rem;
      }
    }
    .badge.sold-out-status {
      z-index: 2;
      font-size: 0.8em;
      position: absolute;
      top: 1.25rem;
      right: 1rem;
    }
    .title {
      font-size: $font-size-base * 1.2;
      margin-bottom: 0;
      color: $primary;
      font-family: $font-family-sans-serif !important;
    }
    .subtitle {
      font-size: $font-size-sm;
      margin-bottom: 0;
      color: $gray-600;
      font-weight: normal;
      text-transform: none;
      @include media-breakpoint-down(md) {
        line-height: 1rem;
        margin-bottom: 0.5rem;
      }
    }
    .location {
      font-weight: 800 !important;
      font-size: $h3-font-size !important;
      font-family: $headings-font-family;
      text-transform: uppercase;
      color: $primary;
      margin-bottom: 0.1rem;
      @include media-breakpoint-down(md) {
        margin-bottom: 1rem;
      }
    }
    &:hover {
      // background-color: lighten($bgcolor, 1%);
    }
  }
}

// PDP

.detail-page {
  h1 {
    margin-top: 0;
  }
  .product-header-title {
    line-height: 1.4;
  }
}

.carousel-control-prev,
.carousel-control-next {
  color: $primary;
  &:hover,
  &:focus {
    color: lighten($primary, 0.2);
  }
}

.blurry-background-image {
  background-color: transparent;
}

// .blurry-background-image:before {
//   background-image: none;
// }

.carousel-indicators {
  @include media-breakpoint-down(sm) {
    display: none;
  }
  position: relative;
  > a,
  > .btn {
    img {
      filter: brightness(0.5);
      margin: 0.3rem !important;
    }

    &:hover,
    &:focus,
    &.active {
      img {
        filter: brightness(1);
      }
    }
  }
}

.product-thumb {
  box-shadow: none;
  border: none;
}
.radio-list-group .product-thumb {
  box-shadow: 0 0 0 1px $dark;
  border: 3px solid $white;
  background: $white;
}

// PDP MOBILE

.container.detail-page {
  @include media-breakpoint-down(md) {
    form {
      text-align: center;
    }
  }
}

// FOOTER

#footer {
  background-color: $body-bg;
  color: $primary;
  border-bottom: 10px solid $primary;
}

#product-listing {
  padding-top: 2rem;
}

.hero-slider {
  margin-bottom: 0;
}

// CARMENS SPECIALS
// BACKDROP

.modal-backdrop.show {
  backdrop-filter: none !important;
}

.offcanvas-collapse,
.offcanvas-collapse-right,
.radio-list-group,
.navbar-nav .nav-item {
  box-shadow: none !important;
}

legend {
  font-weight: $headings-font-weight;
}

.landing-cards {
  .landing-card {
    background: $gray-700;
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    min-height: 100vh * $golden-ratio;
    //@debug aspect-ratio: 1/1;
    padding: map-get($spacers, 3);
    margin-bottom: $grid-gutter-width;
    overflow: hidden;
    text-decoration: none;

    .btn {
      pointer-events: none;
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: transparent;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      transition: transform 0.3s;
    }
    &:hover,
    &:focus,
    &:focus-within {
      &::before {
        transform: scale(1.1);
      }
    }
  }
}

.scale-on-hover {
  transition: transform 0.2s, box-shadow 0.2s;
}

.scale-on-hover:hover,
.scale-on-hover:focus,
.scale-on-hover:focus-within {
  transform: rotate(1deg) translateY(-1.5%);
  box-shadow: 0 1rem 1.5rem -1rem rgb(0 0 0 / 30%);
}

// safari fix from-to dates on nigiri
@include media-breakpoint-up(lg) {
  .nigiri-date {
    text-align: center;
    flex-direction: column;
  }

  .nigiri-date * + * {
    margin-top: -1rem;
  }
}

// FAQ
.faq-question {
  button {
    padding: 0;
    font-size: $h5-font-size;
    font-weight: bold !important;
    // background: #f8f9fa;
    // box-shadow: 0 1.5rem 2rem -1.25rem rgba(0, 0, 0, 0.3);

    // border-left: 3px $gray-500 solid;
    // background-image: linear-gradient(to right, $gray-200, $gray-100);
  }
}

// CHECKOUT MESSAGE
#no-checkout-message {
  font-weight: 900;
}

// ALWAYS SHOW COUPON ENTRY
.shopping-finisher-modal .coupon-container .btn-link {
  display: none;
}

.shopping-finisher-modal .coupon-container .collapse {
  display: block;
  font-weight: 700;
}

// DESIGN HASPA JOKER FORM

#product-form-207 {
  background-color: red;
  padding: 15px;
  border-radius: 5px;
  color: white;
  small {
    color: white;
    opacity: 1;
  }
  .invalid-feedback {
    color: yellow;
    text-decoration: underline;
  }
  .custom-control-input.is-invalid ~ .custom-control-label {
    color: white !important;
  }
  h5::before {
    content: ('');
    background-image: url('./assets/images/haspa_white.png');
    background-size: cover;
    position: relative;
    z-index: 100000;
    left: -20px;
    top: -10px;
    width: 250px;
    height: 50px;
    margin-bottom: 0px;
    display: block;
  }
}
